import { InvitationDetails, GuestUpdate, Settings } from "../models/api/rsvpApiTypes"
import axiosFrontend from "./clientApi"

export function getGuestsByReservationCodeAPI(tenant: string, reservationCode: string) {
  return new Promise<InvitationDetails>((resolve, reject) => {
    axiosFrontend
      .get(`/rsvp/${tenant}/invitation/${reservationCode}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function getStyleSettingsAPI(tenant: string) {
  return new Promise<Settings>((resolve, reject) => {
    axiosFrontend
      .get(`/rsvp/${tenant}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => reject(err))
  })
}
export function updateGuestsConfirmAPI(tenant: string | undefined, payload: GuestUpdate) {
  return new Promise<string[]>((resolve, reject) => {
    axiosFrontend
      .put(`/rsvp/${tenant}`, payload)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => reject(err))
  })
}
