import { GuestDetails, RsvpDetails, StepsTranslationsStrings } from "../../models/api/rsvpApiTypes"
import { BUTTON_CLICK, STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"

interface StepIntolerancesProps {
  buttonColor?: string
  backButtonColor?: string
  strings?: StepsTranslationsStrings[]
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  setStep: (newStep: number) => void
  stepBox: STEP_RSVP[]
}

export default function StepIntolerances({
  buttonColor,
  backButtonColor,
  strings,
  rsvp,
  setStep,
  setRsvp,
  stepBox,
}: StepIntolerancesProps) {
  const setIntolerances = (intolerances: string, guest: GuestDetails) => {
    const newGuests = rsvp.invitation.guests.map((g) => {
      if (g.id === guest.id) {
        g = {
          ...guest,
          rsvp: {
            accepted: g.rsvp.accepted,
            intolerances: intolerances.split(","),
            arrivalDate: g.rsvp.arrivalDate,
            email: g.rsvp.email,
            busTransferNeeded: g.rsvp.busTransferNeeded,
          },
        }
      }
      return g
    })
    setRsvp({ ...rsvp, invitation: { ...rsvp.invitation, guests: newGuests } })
  }

  const setNextStep = (buttonClick: BUTTON_CLICK) => {
    let nextStep = 0
    if (buttonClick === BUTTON_CLICK.NEXT_STEP) {
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.INTOLERANCES) {
          nextStep = stepBox[++index]
        }
      })
      setStep(nextStep)
    } else {
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.INTOLERANCES) {
          nextStep = stepBox[--index]
        }
      })
      setStep(nextStep)
    }
  }

  return (
    <>
      {rsvp.invitation.guests.map((guest, index) => (
        <div key={index} style={{ marginTop: "5px" }}>
          {guest.rsvp.accepted && guest.rsvpSettings.showIntolerances && (
            <>
              <label className="box-form-label">
                {guest.name} {guest.surname}
              </label>
              <input
                className="box-form-input"
                type="text"
                placeholder={strings?.find((x) => x.type === TranslationsStringsType.PLACEHOLDER)?.value}
                value={guest.rsvp.intolerances.join(",")}
                onChange={(e) => setIntolerances(e.currentTarget.value, guest)}
              />
            </>
          )}
        </div>
      ))}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          style={{ backgroundColor: backButtonColor, borderColor: backButtonColor }}
          type="submit"
          className="btn btn-primary box-button-left"
          onClick={() => setNextStep(BUTTON_CLICK.PREVIOUS_STEP)}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.LEFTBUTTON)?.value}
        </button>
        <button
          type="submit"
          className="btn btn-primary box-button-right"
          style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
          onClick={() => setNextStep(BUTTON_CLICK.NEXT_STEP)}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
        </button>
      </div>
    </>
  )
}
