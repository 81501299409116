import { ReactNode } from "react"

interface RsvpBoxProps {
  children: ReactNode
  iconAlt?: string
  iconSource?: string
  title?: string
  description?: string
}

export default function RsvpBox({ children, iconAlt, iconSource, title, description }: RsvpBoxProps) {
  return (
    <div className="box-content">
      {iconAlt && iconAlt ? (
        <div className="box-icon">
          <img alt={iconAlt} src={iconSource} />
        </div>
      ) : null}

      <div className="box-title">
        {title ? <div className="box-main-title">{title}</div> : null}
        {description ? <div className="box-sub-title">{description}</div> : null}
      </div>
      {children ? <div className="box-form">{children}</div> : null}
    </div>
  )
}
