import { useLocation } from "react-router-dom"

import Alert from "./Alert"
import RsvpContent from "../components/RsvpContent"

export default function RsvpPage() {
  const search = useLocation().search
  const tenant = new URLSearchParams(search).get("wedding") ?? undefined
  const reservationCode = new URLSearchParams(search).get("code") ?? undefined

  return <>{tenant ? <RsvpContent tenant={tenant} reservationCode={reservationCode} /> : <Alert />}</>
}
