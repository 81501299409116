import { useEffect, useState } from "react"
import { STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"
import { RsvpDetails, Settings, StepsTranslations } from "../../models/api/rsvpApiTypes"
import { useAppSelector } from "../../hook/useReduxHooks"
import { RootState } from "../../redux/store"
import RsvpBox from "../RsvpBox"
import dateStepIcon from "../../icon/date-icon.svg"
import StepDate from "./StepDate"

interface StepArrivalDateBoxProps {
  settings: Settings
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  stepBox: STEP_RSVP[]
  setStep: (newStep: number) => void
}

export default function StepArrivalDateBox({ settings, rsvp, stepBox, setRsvp, setStep }: StepArrivalDateBoxProps) {
  const [arrivalDateStrings, setArrivalDateStrings] = useState<StepsTranslations | undefined>({
    lang: "",
    strings: [],
  })
  const language = useAppSelector((state: RootState) => state.languageReducer.language).toLocaleLowerCase()

  useEffect(() => {
    const newArrivalDateStrings =
      settings.steps?.find((x) => x.name === "arrivalDate")?.translations.find((x) => x.lang === language) === undefined
        ? settings.steps?.find((x) => x.name === "arrivalDate")?.translations[0]
        : settings.steps?.find((x) => x.name === "arrivalDate")?.translations.find((x) => x.lang === language)
    setArrivalDateStrings(newArrivalDateStrings)
  }, [language, settings.steps])

  return (
    <div className="box">
      <RsvpBox
        iconAlt="dateStepIcon"
        iconSource={dateStepIcon}
        title={arrivalDateStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value}
        description={arrivalDateStrings?.strings.find((x) => x.type === TranslationsStringsType.DESCRIPTION)?.value}
      >
        <StepDate
          buttonColor={settings.rsvpStyle.buttonColor === null ? "#0c6efb" : settings.rsvpStyle.buttonColor}
          backButtonColor={settings.rsvpStyle.backButtonColor === null ? "#a6b7d4" : settings.rsvpStyle.backButtonColor}
          strings={arrivalDateStrings?.strings}
          rsvp={rsvp}
          setRsvp={(rsvp) => setRsvp(rsvp)}
          setStep={(nextStep) => setStep(nextStep)}
          stepBox={stepBox}
        />
      </RsvpBox>
    </div>
  )
}
