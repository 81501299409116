import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { RsvpDetails, Settings } from "../models/api/rsvpApiTypes"
import { getGuestsByReservationCodeAPI } from "../api/rsvpApi"
import { toastWarning } from "../utils/toastMethod"
import { useAppSelector } from "../hook/useReduxHooks"
import { RootState } from "../redux/store"
import { ERROR_TYPE, STEP_RSVP } from "../models/enum/rsvpEnum"
import StepRsvpBox from "./StepRsvp/StepRsvpBox"
import StepIntolerancesBox from "./StepIntolerances/StepIntolerancesBox"
import StepArrivalDateBox from "./StepArrivalDate/StepArrivalDateBox"
import StepBusBox from "./StepBus/StepBusBox"
import StepEmailBox from "./StepEmail/StepEmailBox"
import StepRecapBox from "./StepRecap/StepRecapBox"
import StepResultBox from "./StepResult/StepResultBox"
import toasts from "../json/toast.json"

interface RsvpContentBodyProcedureProps {
  tenant: string
  reservationCode: string
  settings: Settings
}

export default function RsvpContentBodyProcedure({ tenant, reservationCode, settings }: RsvpContentBodyProcedureProps) {
  const [step, setStep] = useState(0)
  const [stepBox, setStepBox] = useState<STEP_RSVP[]>([])
  const [rsvp, setRsvp] = useState<RsvpDetails>()
  const language = useAppSelector((state: RootState) => state.languageReducer.language)
  const navigate = useNavigate()

  const toastStrings =
    toasts.translations.find((x) => x.lang === language) === undefined
      ? toasts.translations[0]
      : toasts.translations.find((x) => x.lang === language)

  useEffect(() => {
    tenant &&
      reservationCode &&
      getGuestsByReservationCodeAPI(tenant, reservationCode)
        .then((result) => {
          const newRsvp: RsvpDetails = {
            invitation: result,
            wedding: settings,
          }
          // set all rsvp.accepted to false (if are null) because
          // the backend doesn't accept a null value for the accepted field
          newRsvp.invitation.guests.forEach((guest) => {
            if (guest.rsvp.accepted === null) {
              guest.rsvp.accepted = false
            }
          })
          setRsvp(newRsvp)
          setStepBox([STEP_RSVP.RSVP])
          if (newRsvp.wedding.rsvpSettings.showIntolerances) {
            let isOnceTrue = false
            result.guests.forEach(function (g) {
              if (g.rsvpSettings.showIntolerances) isOnceTrue = true
            })
            if (isOnceTrue) {
              setStepBox((stepBox) => [...stepBox, STEP_RSVP.INTOLERANCES])
            }
          }
          if (newRsvp.wedding.rsvpSettings.showArrivalDate) {
            let isOnceTrue = false
            result.guests.forEach(function (g) {
              if (g.rsvpSettings.showArrivalDate) isOnceTrue = true
            })
            if (isOnceTrue) {
              setStepBox((stepBox) => [...stepBox, STEP_RSVP.ARRIVAL_DATE])
            }
          }
          if (newRsvp.wedding.rsvpSettings.showBusTransferNeeded) {
            let isOnceTrue = false
            result.guests.forEach(function (g) {
              if (g.rsvpSettings.showBusTransferNeeded) {
                if (g.rsvp.busTransferNeeded === null) g.rsvp.busTransferNeeded = false
                isOnceTrue = true
              }
            })
            if (isOnceTrue) {
              setStepBox((stepBox) => [...stepBox, STEP_RSVP.BUS])
            }
          }
          if (newRsvp.wedding.rsvpSettings.showEmail) {
            let isOnceTrue = false
            result.guests.forEach(function (g) {
              if (g.rsvpSettings.showEmail) isOnceTrue = true
            })
            if (isOnceTrue) {
              setStepBox((stepBox) => [...stepBox, STEP_RSVP.EMAIL])
            }
          }
          setStepBox((stepBox) => [...stepBox, STEP_RSVP.RECAP])
        })
        .catch((error) => {
          if (error.response.status !== 200) {
            switch (error.response.data.Messages[0]) {
              case ERROR_TYPE.INVITATION_NOT_FOUND:
                toastWarning(toastStrings?.pinError !== undefined ? toastStrings?.pinError : "error")
                navigate({ pathname: `/confirm`, search: `?wedding=${tenant}` })
                break
              case ERROR_TYPE.RSVP_NOT_ENABLED:
                toastWarning(toastStrings?.procedureError !== undefined ? toastStrings?.procedureError : "error")
                navigate({ pathname: `/confirm`, search: `?wedding=${tenant}` })
                break
              default:
                navigate(`/error`)
                break
            }
          }
        })
  }, [navigate, reservationCode, tenant, settings, toastStrings?.pinError, toastStrings?.procedureError])

  return (
    <>
      {rsvp ? (
        <>
          {step === STEP_RSVP.RSVP ? (
            <StepRsvpBox settings={settings} rsvp={rsvp} setRsvp={setRsvp} stepBox={stepBox} setStep={setStep} />
          ) : null}
          {step === STEP_RSVP.INTOLERANCES ? (
            <StepIntolerancesBox
              settings={settings}
              rsvp={rsvp}
              setRsvp={setRsvp}
              stepBox={stepBox}
              setStep={setStep}
            />
          ) : null}
          {step === STEP_RSVP.ARRIVAL_DATE ? (
            <StepArrivalDateBox settings={settings} rsvp={rsvp} setRsvp={setRsvp} stepBox={stepBox} setStep={setStep} />
          ) : null}
          {step === STEP_RSVP.BUS ? (
            <StepBusBox settings={settings} rsvp={rsvp} setRsvp={setRsvp} stepBox={stepBox} setStep={setStep} />
          ) : null}
          {step === STEP_RSVP.EMAIL ? (
            <StepEmailBox settings={settings} rsvp={rsvp} setRsvp={setRsvp} stepBox={stepBox} setStep={setStep} />
          ) : null}
          {step === STEP_RSVP.RECAP ? (
            <StepRecapBox
              settings={settings}
              tenant={tenant}
              reservationCode={reservationCode}
              rsvp={rsvp}
              stepBox={stepBox}
              setStep={setStep}
            />
          ) : null}
          {step === STEP_RSVP.RESULT ? (
            <StepResultBox settings={settings} tenant={tenant} reservationCode={reservationCode} />
          ) : null}
        </>
      ) : null}
    </>
  )
}
