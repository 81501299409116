import { DropdownButton, Dropdown } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../hook/useReduxHooks"
import { RootState } from "../../redux/store"
import { setLanguage } from "./languageSlice"
import LangugeFlag from "./LangugeFlag"
import "./style.css"

interface LanguageSwitcherProps {
  languages: string[]
  backgroundColor: string | undefined
  borderColor: string | undefined
}

export const LanguageSwitcher = ({ languages, backgroundColor, borderColor }: LanguageSwitcherProps) => {
  const language = useAppSelector((state: RootState) => state.languageReducer.language)
  const dispatch = useAppDispatch()

  const onSelectHanlde = (e: string | null) => {
    const filter = languages.filter((x) => x === e)
    if (filter !== undefined && filter.length > 0) {
      dispatch(setLanguage({ language: filter[0] }))
    } else {
      dispatch(setLanguage({ language: languages[0] }))
    }
  }

  return (
    <>
      {languages.length > 1 ? (
        <DropdownButton
          id="language-switcher"
          style={{ backgroundColor, borderColor }}
          title={
            <>
              <div style={{ display: "flex", marginRight: "6px" }}>
                <LangugeFlag language={language} />
              </div>
              <div style={{ display: "flex" }}>{language}</div>
            </>
          }
          onSelect={(e) => onSelectHanlde(e)}
        >
          {languages.map((lang, index) => {
            return (
              <Dropdown.Item key={index} eventKey={lang}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <LangugeFlag language={lang} />
                  <div style={{ marginLeft: "10px" }}>{lang}</div>
                </div>
              </Dropdown.Item>
            )
          })}
        </DropdownButton>
      ) : null}
    </>
  )
}
