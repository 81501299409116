import { GuestDetails, RsvpDetails, StepsTranslationsStrings } from "../../models/api/rsvpApiTypes"
import { BUTTON_CLICK, STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"

interface StepBusProps {
  buttonColor?: string
  backButtonColor?: string
  strings?: StepsTranslationsStrings[]
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  setStep: (newStep: number) => void
  stepBox: STEP_RSVP[]
}

export default function StepBus({
  buttonColor,
  backButtonColor,
  strings,
  rsvp,
  setStep,
  stepBox,
  setRsvp,
}: StepBusProps) {
  const setFlag = (busTransferNeeded: boolean, guest: GuestDetails) => {
    const newGuests = rsvp.invitation.guests.map((g) => {
      if (g.id === guest.id) {
        g = {
          ...guest,
          rsvp: {
            accepted: g.rsvp.accepted,
            arrivalDate: g.rsvp.arrivalDate,
            email: g.rsvp.email,
            intolerances: g.rsvp.intolerances,
            busTransferNeeded,
          },
        }
      }
      return g
    })
    setRsvp({ ...rsvp, invitation: { ...rsvp.invitation, guests: newGuests } })
  }

  const setNextStep = (buttonClick: BUTTON_CLICK) => {
    let nextStep = 0
    if (buttonClick === BUTTON_CLICK.NEXT_STEP) {
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.BUS) {
          nextStep = stepBox[++index]
        }
      })
      setStep(nextStep)
    } else {
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.BUS) {
          nextStep = stepBox[--index]
        }
      })
      setStep(nextStep)
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "right", color: "#27272E", fontWeight: "bold" }}>
        {strings?.find((x) => x.type === TranslationsStringsType.VALUETITLE)?.value}
      </div>
      {rsvp.invitation.guests.map((guest, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }}>
          {guest.rsvp.accepted && guest.rsvpSettings.showBusTransferNeeded && (
            <>
              <label className="box-form-label">
                {guest.name} {guest.surname}
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={guest.rsvp.busTransferNeeded ?? false}
                  onChange={(e) => setFlag(e.target.checked, guest)}
                />
              </div>
            </>
          )}
        </div>
      ))}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          style={{ backgroundColor: backButtonColor, borderColor: backButtonColor }}
          type="submit"
          className="btn btn-primary box-button-left"
          onClick={() => setNextStep(BUTTON_CLICK.PREVIOUS_STEP)}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.LEFTBUTTON)?.value}
        </button>
        <button
          type="submit"
          className="btn btn-primary box-button-right"
          style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
          onClick={() => setNextStep(BUTTON_CLICK.NEXT_STEP)}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
        </button>
      </div>
    </>
  )
}
