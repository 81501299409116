import { configureStore } from "@reduxjs/toolkit"
import languageReducer from "../components/LangugaeSwitcher/languageSlice"

export const store = configureStore({
  reducer: { languageReducer },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
