import RsvpBox from "../components/RsvpBox"
import wamooIcon from "../icon/wamoo_icon.svg"

export default function Alert() {
  return (
    <div className="app-content" style={{ width: "inherit", height: "inherit" }}>
      <div className="app-header">
        <img alt="wamooIcon" src={wamooIcon} />
      </div>
      <div className="app-body">
        <div className="box" style={{ alignItems: "center" }}>
          <RsvpBox title={"Attention!"} description={"Something went wrong!"}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <small>{"Verify the URL integrity."}</small>
            </div>
          </RsvpBox>
        </div>
      </div>
    </div>
  )
}
