export enum TranslationsStringsType {
  TITLE = "Title",
  DESCRIPTION = "Description",
  MAINBUTTON = "MainButton",
  LEFTBUTTON = "LeftButton",
  VALUETITLE = "ValueTitle",
  PLACEHOLDER = "Placeholder",
}

export enum STEP_RSVP {
  RSVP = 0,
  INTOLERANCES = 1,
  ARRIVAL_DATE = 2,
  BUS = 3,
  EMAIL = 4,
  RECAP = 5,
  RESULT = 6,
}

export enum BUTTON_CLICK {
  PREVIOUS_STEP = 0,
  NEXT_STEP = 1,
}

export enum ERROR_TYPE {
  INVITATION_NOT_FOUND = "invitation.notFound",
  RSVP_NOT_ENABLED = "rsvp.notEnabled",
  RSVP_CONFIRM_FAILED = "rsvp.failed",
}
