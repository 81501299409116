import { useEffect, useState } from "react"
import { STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"
import { RsvpDetails, Settings, StepsTranslations } from "../../models/api/rsvpApiTypes"
import { useAppSelector } from "../../hook/useReduxHooks"
import { RootState } from "../../redux/store"
import RsvpBox from "../RsvpBox"
import StepRsvp from "./StepRsvp"
import rsvpStepIcon from "../../icon/rsvp-step-icon.svg"

interface StepRsvpBoxProps {
  settings: Settings
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  stepBox: STEP_RSVP[]
  setStep: (newStep: number) => void
}

export default function StepRsvpBox({ settings, rsvp, stepBox, setRsvp, setStep }: StepRsvpBoxProps) {
  const [rsvpStrings, setRsvpStrings] = useState<StepsTranslations | undefined>({
    lang: "",
    strings: [],
  })
  const language = useAppSelector((state: RootState) => state.languageReducer.language).toLocaleLowerCase()

  useEffect(() => {
    const newRsvpStrings =
      settings.steps?.find((x) => x.name === "rsvp")?.translations.find((x) => x.lang === language) === undefined
        ? settings.steps?.find((x) => x.name === "rsvp")?.translations[0]
        : settings.steps?.find((x) => x.name === "rsvp")?.translations.find((x) => x.lang === language)
    setRsvpStrings(newRsvpStrings)
  }, [language, settings.steps])

  return (
    <div className="box">
      <RsvpBox
        iconAlt="rsvpStepIcon"
        iconSource={rsvpStepIcon}
        title={
          rsvpStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value === undefined ||
          rsvpStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value === null
            ? "Hey, " + rsvp.invitation.name
            : rsvpStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value + rsvp.invitation.name
        }
        description={rsvpStrings?.strings.find((x) => x.type === TranslationsStringsType.DESCRIPTION)?.value}
      >
        <StepRsvp
          buttonColor={settings.rsvpStyle.buttonColor === null ? "#0c6efb" : settings.rsvpStyle.buttonColor}
          strings={rsvpStrings?.strings}
          rsvp={rsvp}
          setRsvp={(rsvp) => setRsvp(rsvp)}
          setStep={(nextStep) => setStep(nextStep)}
          stepBox={stepBox}
        />
      </RsvpBox>
    </div>
  )
}
