import axios from "axios"

// Axios instance without authentication
const axiosFrontend = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
})

export default axiosFrontend
