import { Settings } from "../models/api/rsvpApiTypes"
import { useAppSelector } from "../hook/useReduxHooks"
import { RootState } from "../redux/store"
import { TranslationsStringsType } from "../models/enum/rsvpEnum"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import RsvpBox from "./RsvpBox"
import inputErrorMessage from "../json/inputErrorMessage.json"

interface RsvpContentBodyInsertPinProps {
  tenant: string
  settings: Settings
}

export default function RsvpContentBodyInsertPin({ tenant, settings }: RsvpContentBodyInsertPinProps) {
  const [code, setCode] = useState("")
  const [error, setError] = useState(false)
  const language = useAppSelector((state: RootState) => state.languageReducer.language).toLocaleLowerCase()
  const navigate = useNavigate()

  const pinStrings =
    settings.steps?.find((x) => x.name === "pin")?.translations.find((x) => x.lang === language) === undefined
      ? settings.steps?.find((x) => x.name === "pin")?.translations[0]
      : settings.steps?.find((x) => x.name === "pin")?.translations.find((x) => x.lang === language)

  const inputErrorMessageString =
    inputErrorMessage.translations.find((x) => x.lang === language) === undefined
      ? inputErrorMessage.translations[0]
      : inputErrorMessage.translations.find((x) => x.lang === language)

  const checkText = () => {
    if (code.length === 0) setError(true)
    else navigate({ pathname: `/confirm`, search: `?wedding=${tenant}&code=${code}` })
  }

  return (
    <div className="box">
      <RsvpBox
        title={pinStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value}
        description={pinStrings?.strings.find((x) => x.type === TranslationsStringsType.DESCRIPTION)?.value}
      >
        <input
          className="box-form-input"
          type="text"
          placeholder="AB89"
          style={{ textAlign: "center" }}
          onChange={(e) => setCode(e.currentTarget.value)}
        ></input>
        {code.length === 0 && error ? (
          <div className="box-form-input-error">{inputErrorMessageString?.inpuPinCodeError}</div>
        ) : null}
        <div>
          <button
            type="submit"
            className="btn btn-primary box-button-single"
            style={{
              backgroundColor: settings.rsvpStyle.buttonColor === null ? "#0c6efb" : settings.rsvpStyle.buttonColor,
              borderColor: settings.rsvpStyle.buttonColor === null ? "#0c6efb" : settings.rsvpStyle.buttonColor,
            }}
            onClick={checkText}
          >
            {pinStrings?.strings.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
          </button>
        </div>
      </RsvpBox>
    </div>
  )
}
