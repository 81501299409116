import { createContext, ReactNode } from "react"

export interface LangugeProps {
  language: string
  flag: ReactNode
}

export interface ThemeContext {
  language: LangugeProps
  setLanguage: (lang: LangugeProps) => void
}

const defaultState: ThemeContext = {
  language: {
    language: "",
    flag: <></>,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLanguage: () => {},
}

export const LanguageContext = createContext<ThemeContext>(defaultState)
