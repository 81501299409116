import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface LanguageProps {
  language: string
}

const initialState: LanguageProps = {
  language: "",
}

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state: LanguageProps, action: PayloadAction<{ language: string }>) => {
      return {
        ...state,
        language: action.payload.language,
      }
    },
  },
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer
