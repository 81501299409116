import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { updateGuestsConfirmAPI } from "../../api/rsvpApi"
import { GuestUpdate, RsvpDetails, StepsTranslationsStrings } from "../../models/api/rsvpApiTypes"
import { STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"
import { LanguageContext } from "../../utils/LanguageContext"
import { toastWarning } from "../../utils/toastMethod"
import toasts from "../../json/toast.json"

interface StepRecapProps {
  buttonColor?: string
  backButtonColor?: string
  strings?: StepsTranslationsStrings[]
  rsvp: RsvpDetails
  tenant: string
  reservationCode: string | undefined
  setStep: (newStep: number) => void
  stepBox: STEP_RSVP[]
}

export default function StepRecap({
  buttonColor,
  backButtonColor,
  strings,
  rsvp,
  tenant,
  reservationCode,
  setStep,
  stepBox,
}: StepRecapProps) {
  const { language } = useContext(LanguageContext)
  const navigate = useNavigate()

  const lang = language.language.toLowerCase()
  const toastStrings =
    toasts.translations.find((x) => x.lang === lang) === undefined
      ? toasts.translations[0]
      : toasts.translations.find((x) => x.lang === lang)

  const onSubmit = async () => {
    if (rsvp != null && rsvp.invitation != null) {
      const guestConfirm: GuestUpdate = {
        reservationCode,
        guests: rsvp.invitation.guests,
      }
      updateGuestsConfirmAPI(tenant, guestConfirm)
        .then(() => {
          setStep(STEP_RSVP.RESULT)
        })
        .catch(() => {
          toastWarning(toastStrings?.genericError !== undefined ? toastStrings.genericError : "error")
          navigate({ pathname: `/confirm`, search: `?wedding=${tenant}` })
        })
    }
  }

  const setNextStep = () => {
    let hasOneGuestAccepted = false
    rsvp.invitation.guests.forEach(function (s) {
      if (s.rsvp.accepted === true) {
        hasOneGuestAccepted = true
      }
    })

    if (hasOneGuestAccepted) {
      let nextStep = 0
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.RECAP) {
          nextStep = stepBox[--index]
        }
      })
      setStep(nextStep)
    } else {
      setStep(0)
    }
  }

  return (
    <>
      {rsvp.invitation.guests.map((guest, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "row", marginTop: "5px" }}>
          <label className="box-form-label ">
            {guest.name} {guest.surname}
          </label>
          <div style={{ alignItems: "flex-end" }}>{guest.rsvp.accepted ? "✅" : "❌"}</div>
        </div>
      ))}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          style={{ backgroundColor: backButtonColor, borderColor: backButtonColor }}
          type="submit"
          className="btn btn-primary box-button-left"
          onClick={() => setNextStep()}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.LEFTBUTTON)?.value}
        </button>
        <button
          type="submit"
          className="btn btn-primary box-button-right"
          style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
          onClick={onSubmit}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
        </button>
      </div>
    </>
  )
}
