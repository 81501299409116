import { Settings } from "../models/api/rsvpApiTypes"
import RsvpContentBodyInsertPin from "./RsvpContentBodyInsertPin"
import RsvpContentBodyProcedure from "./RsvpContentBodyProcedure"

interface RsvpPageContentProps {
  tenant: string
  reservationCode: string | undefined
  settings: Settings
}

export default function RsvpPageContent({ tenant, reservationCode, settings }: RsvpPageContentProps) {
  return (
    <div className="app-body">
      {tenant && tenant.length > 0 && reservationCode === undefined && settings && (
        <RsvpContentBodyInsertPin settings={settings} tenant={tenant} />
      )}

      {reservationCode && reservationCode.length > 0 && settings && (
        <RsvpContentBodyProcedure settings={settings} tenant={tenant} reservationCode={reservationCode} />
      )}
    </div>
  )
}
