import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom"
import "./App.css"
import "./style/custom-style.css"
import RsvpPage from "./pages/RsvpPage"
import Alert from "./pages/Alert"
import { Slide, ToastContainer } from "react-toastify"
import { Provider } from "react-redux"
import { store } from "./redux/store"

function App() {
  return (
    <Provider store={store}>
      <div className="app">
        <Router>
          <Routes>
            <Route path="/error" element={<Alert />} />
            <Route path="/confirm" element={<RsvpPage />} />
            <Route path="*" element={<Navigate to="/error" />} />
          </Routes>
        </Router>
        <ToastContainer theme="colored" position="bottom-right" autoClose={4000} transition={Slide} />
      </div>
    </Provider>
  )
}

export default App
