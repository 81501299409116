import { TranslationsStringsType } from "../../models/enum/rsvpEnum"
import { Settings, StepsTranslations } from "../../models/api/rsvpApiTypes"
import { useAppSelector } from "../../hook/useReduxHooks"
import { RootState } from "../../redux/store"
import { useEffect, useState } from "react"
import RsvpBox from "../RsvpBox"
import StepResult from "./StepResult"
import resultStepIcon from "../../icon/result-icon.svg"

interface StepResultBoxProps {
  settings: Settings
  tenant: string
  reservationCode: string
}

export default function StepResultBox({ settings, tenant, reservationCode }: StepResultBoxProps) {
  const [resultStrings, setResultStrings] = useState<StepsTranslations | undefined>({
    lang: "",
    strings: [],
  })
  const language = useAppSelector((state: RootState) => state.languageReducer.language).toLocaleLowerCase()

  useEffect(() => {
    const newResultStrings =
      settings.steps?.find((x) => x.name === "final")?.translations.find((x) => x.lang === language) === undefined
        ? settings.steps?.find((x) => x.name === "final")?.translations[0]
        : settings.steps?.find((x) => x.name === "final")?.translations.find((x) => x.lang === language)
    setResultStrings(newResultStrings)
  }, [language, settings.steps])

  return (
    <div className="box">
      <RsvpBox
        iconAlt="resultStepIcon"
        iconSource={resultStepIcon}
        title={resultStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value}
        description={resultStrings?.strings.find((x) => x.type === TranslationsStringsType.DESCRIPTION)?.value}
      >
        <StepResult
          buttonColor={settings.rsvpStyle.buttonColor === null ? "#0c6efb" : settings.rsvpStyle.buttonColor}
          strings={resultStrings?.strings}
          tenant={tenant}
          reservationCode={reservationCode}
        />
      </RsvpBox>
    </div>
  )
}
