import itaFlagIcon from "../../icon/ita-flag.svg"
import ukFlagIcon from "../../icon/uk-flag.svg"

enum RsvpSupportedLanguages {
  IT = "IT",
  EN = "EN",
}

interface LangugeFlagProps {
  language: string
}

export default function LangugeFlag({ language }: LangugeFlagProps) {
  if (language === RsvpSupportedLanguages.IT) {
    return <img src={itaFlagIcon} alt="flagIt" />
  } else return <img src={ukFlagIcon} alt="flagUk" />
}
