import { GuestDetails, RsvpDetails, StepsTranslationsStrings } from "../../models/api/rsvpApiTypes"
import { BUTTON_CLICK, STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"
import inputErrorMessage from "../../json/inputErrorMessage.json"

interface StepEmailProps {
  buttonColor?: string
  backButtonColor?: string
  strings?: StepsTranslationsStrings[]
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  setStep: (newStep: number) => void
  stepBox: STEP_RSVP[]
  languge: string
}

export default function StepEmail({
  buttonColor,
  backButtonColor,
  strings,
  rsvp,
  setStep,
  stepBox,
  setRsvp,
  languge,
}: StepEmailProps) {
  const inputErrorMessageString =
    inputErrorMessage.translations.find((x) => x.lang === languge) === undefined
      ? inputErrorMessage.translations[0]
      : inputErrorMessage.translations.find((x) => x.lang === languge)

  const setIntolerances = (email: string, guest: GuestDetails) => {
    const newGuests = rsvp.invitation.guests.map((g) => {
      if (g.id === guest.id) {
        g = {
          ...guest,
          rsvp: {
            accepted: g.rsvp.accepted,
            arrivalDate: g.rsvp.arrivalDate,
            email: email.length > 0 ? email : null,
            intolerances: g.rsvp.intolerances,
            busTransferNeeded: g.rsvp.busTransferNeeded,
          },
        }
      }
      return g
    })
    setRsvp({ ...rsvp, invitation: { ...rsvp.invitation, guests: newGuests } })
  }

  const setNextStep = (buttonClick: BUTTON_CLICK) => {
    let nextStep = 0
    if (buttonClick === BUTTON_CLICK.NEXT_STEP) {
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.EMAIL) {
          nextStep = stepBox[++index]
        }
      })
      setStep(nextStep)
    } else {
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.EMAIL) {
          nextStep = stepBox[--index]
        }
      })
      setStep(nextStep)
    }
  }

  return (
    <>
      {rsvp.invitation.guests.map((guest, index) => (
        <div key={index} style={{ marginTop: "5px" }}>
          {guest.rsvp.accepted && guest.rsvpSettings.showEmail && (
            <>
              <label className="box-form-label">
                {guest.name} {guest.surname}
              </label>
              <input
                className="box-form-input"
                type="email"
                placeholder={strings?.find((x) => x.type === TranslationsStringsType.PLACEHOLDER)?.value}
                value={guest.rsvp.email ?? ""}
                onChange={(e) => setIntolerances(e.currentTarget.value, guest)}
              />
              {guest.rsvp.email !== null && guest.rsvp.email?.length > 0 && !/\S+@\S+\.\S+/.test(guest.rsvp.email) ? (
                <div className="box-form-input-error">{inputErrorMessageString?.inputEmailNotValid}</div>
              ) : null}
            </>
          )}
        </div>
      ))}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          style={{ backgroundColor: backButtonColor, borderColor: backButtonColor }}
          type="submit"
          className="btn btn-primary box-button-left"
          onClick={() => setNextStep(BUTTON_CLICK.PREVIOUS_STEP)}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.LEFTBUTTON)?.value}
        </button>
        <button
          type="submit"
          className="btn btn-primary box-button-right"
          style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
          onClick={() => setNextStep(BUTTON_CLICK.NEXT_STEP)}
        >
          {strings?.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
        </button>
      </div>
    </>
  )
}
