import { GuestDetails, RsvpDetails, StepsTranslationsStrings } from "../../models/api/rsvpApiTypes"
import { STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"

interface StepRsvpProps {
  buttonColor?: string
  strings?: StepsTranslationsStrings[]
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  setStep: (newStep: number) => void
  stepBox: STEP_RSVP[]
}

export default function StepRsvp({ buttonColor, strings, rsvp, setStep, stepBox, setRsvp }: StepRsvpProps) {
  const setFlag = (accepted: boolean, guest: GuestDetails) => {
    const newGuests = rsvp.invitation.guests.map((g) => {
      if (g.id === guest.id) {
        g = {
          ...guest,
          rsvp: {
            accepted,
            arrivalDate: accepted ? g.rsvp.arrivalDate : null,
            email: accepted ? g.rsvp.email : null,
            intolerances: accepted ? g.rsvp.intolerances : [],
            busTransferNeeded: accepted ? g.rsvp.busTransferNeeded : false,
          },
        }
      }
      return g
    })
    setRsvp({ ...rsvp, invitation: { ...rsvp.invitation, guests: newGuests } })
  }

  const setNextStep = () => {
    let hasOneGuestAccepted = false
    rsvp.invitation.guests.forEach(function (s) {
      if (s.rsvp.accepted === true) {
        hasOneGuestAccepted = true
      }
    })

    if (hasOneGuestAccepted) {
      let nextStep = 0
      stepBox.forEach(function (s, index) {
        if (s === STEP_RSVP.RSVP) {
          nextStep = stepBox[++index]
        }
      })
      setStep(nextStep)
    } else {
      setStep(stepBox[stepBox.length - 1])
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "right", color: "#27272E", fontWeight: "bold" }}>
        {strings?.find((x) => x.type === TranslationsStringsType.VALUETITLE)?.value}
      </div>
      {rsvp.invitation.guests.map((guest, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }}>
          <label className="box-form-label">
            {guest.name} {guest.surname}
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              placeholder="ABCD-ABCD"
              role="switch"
              checked={guest.rsvp.accepted}
              onChange={(e) => setFlag(e.target.checked, guest)}
            />
          </div>
        </div>
      ))}

      <button
        type="submit"
        className="btn btn-primary box-button-single"
        style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
        onClick={setNextStep}
      >
        {strings?.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
      </button>
    </>
  )
}
