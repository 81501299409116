import { LanguageSwitcher } from "./LangugaeSwitcher"
import wamooIcon from "../icon/wamoo_icon.svg"
import wamooWhiteIcon from "../icon/wamoo_white_icon.svg"

interface RsvpPageHeaderProps {
  languages: string[]
  backgroundColor: string
  useLightLogo: boolean
}

export default function RsvpPageHeader({ languages, backgroundColor, useLightLogo }: RsvpPageHeaderProps) {
  return (
    <div className="app-header">
      <LanguageSwitcher languages={languages} backgroundColor={backgroundColor} borderColor={backgroundColor} />
      <img style={{ marginTop: "30px" }} alt="wamooIcon" src={useLightLogo ? wamooWhiteIcon : wamooIcon} />
    </div>
  )
}
