import { useEffect, useState } from "react"
import { STEP_RSVP, TranslationsStringsType } from "../../models/enum/rsvpEnum"
import { RsvpDetails, Settings, StepsTranslations } from "../../models/api/rsvpApiTypes"
import { useAppSelector } from "../../hook/useReduxHooks"
import { RootState } from "../../redux/store"
import RsvpBox from "../RsvpBox"
import StepEmail from "./StepEmail"
import mailStepIcon from "../../icon/email-icon.svg"

interface StepEmailBoxProps {
  settings: Settings
  rsvp: RsvpDetails
  setRsvp: (rsvp: RsvpDetails) => void
  stepBox: STEP_RSVP[]
  setStep: (newStep: number) => void
}

export default function StepEmailBox({ settings, rsvp, stepBox, setRsvp, setStep }: StepEmailBoxProps) {
  const [emailStrings, setEmailStringsStrings] = useState<StepsTranslations | undefined>({
    lang: "",
    strings: [],
  })
  const language = useAppSelector((state: RootState) => state.languageReducer.language).toLocaleLowerCase()

  useEffect(() => {
    const newEmailStrings =
      settings.steps?.find((x) => x.name === "email")?.translations.find((x) => x.lang === language) === undefined
        ? settings.steps?.find((x) => x.name === "email")?.translations[0]
        : settings.steps?.find((x) => x.name === "email")?.translations.find((x) => x.lang === language)
    setEmailStringsStrings(newEmailStrings)
  }, [language, settings.steps])

  return (
    <div className="box">
      <RsvpBox
        iconAlt="mailStepIcon"
        iconSource={mailStepIcon}
        title={emailStrings?.strings.find((x) => x.type === TranslationsStringsType.TITLE)?.value}
        description={emailStrings?.strings.find((x) => x.type === TranslationsStringsType.DESCRIPTION)?.value}
      >
        <StepEmail
          buttonColor={settings.rsvpStyle.buttonColor === null ? "#0c6efb" : settings.rsvpStyle.buttonColor}
          backButtonColor={settings.rsvpStyle.backButtonColor === null ? "#a6b7d4" : settings.rsvpStyle.backButtonColor}
          strings={emailStrings?.strings}
          rsvp={rsvp}
          setRsvp={(rsvp) => setRsvp(rsvp)}
          stepBox={stepBox}
          setStep={(nextStep) => setStep(nextStep)}
          languge={language}
        />
      </RsvpBox>
    </div>
  )
}
