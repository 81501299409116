import { StepsTranslationsStrings } from "../../models/api/rsvpApiTypes"
import { TranslationsStringsType } from "../../models/enum/rsvpEnum"

interface StepResultProps {
  buttonColor?: string
  strings?: StepsTranslationsStrings[]
  tenant: string
  reservationCode: string
}

export default function StepResult({ buttonColor, strings, tenant, reservationCode }: StepResultProps) {
  return (
    <button
      type="submit"
      className="btn btn-primary box-button-single"
      style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
    >
      <a
        style={{ color: "white", textDecoration: "none" }}
        href={
          process.env.REACT_APP_LINK_REDIRECT_URL + "/website/" + tenant + "?code=" + reservationCode + "&source=rsvp"
        }
      >
        {strings?.find((x) => x.type === TranslationsStringsType.MAINBUTTON)?.value}
      </a>
    </button>
  )
}
