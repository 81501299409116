import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getStyleSettingsAPI } from "../api/rsvpApi"
import { toastWarning } from "../utils/toastMethod"
import { Settings } from "../models/api/rsvpApiTypes"
import { useAppDispatch } from "../hook/useReduxHooks"
import { setLanguage } from "./LangugaeSwitcher/languageSlice"
import RsvpContentHeader from "./RsvpContentHeader"
import RsvpContentBody from "./RsvpContentBody"

interface RsvpContentProps {
  tenant: string
  reservationCode: string | undefined
}

export default function RsvpContent({ tenant, reservationCode }: RsvpContentProps) {
  const [settings, setSettings] = useState<Settings>()
  const [tenantLanguages, setTenantLanguages] = useState<string[]>([""])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    tenant &&
      getStyleSettingsAPI(tenant)
        .then((result) => {
          setSettings(result)
          const tenantLanguages: string[] = []
          result.steps[0].translations.forEach(function (language) {
            tenantLanguages.push(language.lang.toUpperCase())
          })
          dispatch(setLanguage({ language: tenantLanguages[0] }))
          setTenantLanguages(tenantLanguages)
        })
        .catch(() => {
          toastWarning("Something went wrong. Try it !")
          navigate({ pathname: `/error` })
        })
  }, [dispatch, navigate, tenant])

  return (
    <>
      {settings ? (
        <div
          className="app-content"
          style={{
            backgroundColor:
              settings.rsvpStyle.backgroundColor === null ? "inherit" : settings.rsvpStyle.backgroundColor,
            fontFamily: settings.rsvpStyle.fontFamily === null ? "inherit" : settings.rsvpStyle.fontFamily,
            width: "inherit",
            height: "inherit",
          }}
        >
          <RsvpContentHeader
            languages={tenantLanguages}
            backgroundColor={settings.rsvpStyle.backgroundColor}
            useLightLogo={settings.rsvpStyle.useLightLogo}
          />
          <RsvpContentBody tenant={tenant} reservationCode={reservationCode} settings={settings} />
        </div>
      ) : null}
    </>
  )
}
